<template>
  <div id="cashCollection">
    <v-row>
      <v-col cols="12" class="title-col">
        <div class="title">
          <h1>{{ $t("Transaction") }}</h1>
        </div>
      </v-col>
    </v-row>
    <v-form v-model="valid" ref="form" :lazy-validation="lazy">
      <v-row>
        <v-col cols="12" md="6">
          <div class="box ">
            <h4 class="second-title">{{ $t("Select Payment") }}</h4>
            <ul class="payment-methods text-center">
              <li class="text-center disabled">
                <p class="icon" @click="selectPaymentMethod('Visa')">
                  <i class="fas fa-money-check"></i>
                </p>
                <p class="text">{{ $t("Visa") }}</p>
              </li>

              <li class="text-center" :class="submitted ? 'disabled' : ''">
                <p
                  class="icon selectable"
                  :class="paymentObject.type == 'Cash' ? 'active' : ''"
                  @click="selectPaymentMethod('Cash')"
                >
                  <i class="far fa-money-bill-alt"></i>
                </p>
                <p class="text">{{ $t("Cash") }}</p>
              </li>

              <li class="text-center" :class="submitted ? 'disabled' : ''">
                <p
                  class="icon selectable"
                  :class="paymentObject.type == 'Bank Transfer' ? 'active' : ''"
                  @click="selectPaymentMethod('Bank Transfer')"
                >
                  <i class="fas fa-dollar-sign"></i>
                </p>
                <p class="text">{{ $t("Bank Transfer") }}</p>
              </li>

              <li class="text-center" :class="submitted ? 'disabled' : ''">
                <p
                  class="icon selectable"
                  :class="paymentObject.type == 'Check' ? 'active' : ''"
                  @click="selectPaymentMethod('Check')"
                >
                  <i class="far fa-credit-card"></i>
                </p>
                <p class="text">{{ $t("Check") }}</p>
              </li>
            </ul>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <div class="box">
            <v-row>
              <v-col cols="12" md="6">
                <p class="key">{{ $t("Transaction Id") }}</p>
                <p class="value">{{ paymentObject.transaction_id }}</p>
              </v-col>
              <v-col cols="10" md="6">
                <p class="key">{{ $t("Transaction Date") }}</p>
                <v-text-field
                  id="transaction_date"
                  autocomplete="off"
                  v-model="paymentObject.transaction_date"
                  solo
                  dense
                  :disabled="submitted"
                  @keydown.prevent
                  append-icon="event"
                ></v-text-field>
              </v-col>

              <v-row>
                <v-col cols="12" md="6">
                  <p class="key" v-if="paymentObject.type == 'Cash'">
                    {{ $t("Voucher Number") }}
                  </p>
                  <p class="key" v-if="paymentObject.type == 'Bank Transfer'">
                    {{ $t("Transfer Number") }}
                  </p>
                  <p class="key" v-if="paymentObject.type == 'Check'">
                    {{ $t("Check Number") }}
                  </p>
                  <v-text-field
                    autocomplete="off"
                    v-model="paymentObject.transfer_number"
                    solo
                    dense
                    :rules="[validationRules.required]"
                    :disabled="paymentObject.type == 'Cash'"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="6">
                  <p class="key" v-if="paymentObject.type == 'Bank Transfer'">
                    {{ $t("Transfer Date") }}
                  </p>
                  <p class="key" v-if="paymentObject.type == 'Check'">
                    {{ $t("Check Date") }}
                  </p>
                  <v-text-field
                    v-if="
                      paymentObject.type == 'Bank Transfer' ||
                        paymentObject.type == 'Check'
                    "
                    id="transfer_date"
                    autocomplete="off"
                    v-model="paymentObject.transfer_date"
                    solo
                    dense
                    :rules="[checkTransferDate(paymentObject.transfer_date)]"
                    @keydown.prevent
                    append-icon="event"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-row>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="2">
          <h3 class="second-title">{{ $t("Payment Details") }}</h3>
        </v-col>
        <v-col cols="12" md="12">
          <v-row>
            <v-col cols="12" sm="6" md="3">
              <label for="select payment" class="payment_value">{{
                $t("Cash Point")
              }}</label>
              <v-select
                solo
                :items="cash_points"
                v-model="paymentObject.cash_point_id"
                :disabled="submitted"
                item-text="name"
                item-value="id"
                :rules="[validationRules.required]"
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <label for="select payment" class="payment_value">{{
                $t("Partner")
              }}</label>
              <v-select
                solo
                :items="partners"
                item-text="name"
                item-value="id"
                :rules="[validationRules.required]"
                v-model="paymentObject.partner_id"
                @change="getPartnerCode()"
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <label for="select payment" class="payment_value">{{
                $t("Code")
              }}</label>
              <v-text-field solo v-model="code" disabled> </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3"
              ><label for="select payment" class="payment_value"
                >{{ $t("Tax Registration Number") }}</label
              >
              <v-text-field solo v-model="paymentObject.tax_registration">
              </v-text-field
            ></v-col>
            <v-col cols="12">
              <label for="textarea" class="payment_value">{{
                $t("Notes")
              }}</label>
              <v-textarea
                rows="2"
                v-model="paymentObject.notes"
                row-height="5"
                solo
              ></v-textarea>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <hr class="basic" />

      <v-row>
        <v-col cols="12" md="12">
          <v-data-table
            :options.sync="options"
            :headers="headers"
            :items="paymentObject.items"
            class="elevation-1 level1 "
            loading-text="Loading... Please wait"
            hide-default-footer
          >
            <template v-slot:item.code="{ item }">
              <v-text-field
                style="margin-top:15px !important;"
                v-model="item.code"
                solo
                :rules="[validationRules.required]"
                @keyup="
                  getNameAccount(paymentObject.items.indexOf(item), item.code)
                "
              ></v-text-field>
            </template>
            <template v-slot:item.account_id="{ item }">
              <v-select
                style="margin-top:15px !important;"
                solo
                :items="accounts"
                v-model="item.account_id"
                item-text="name"
                item-value="id"
                :rules="[validationRules.required]"
                @change="
                  getCodeAccount(
                    paymentObject.items.indexOf(item),
                    item.account_id
                  )
                "
              >
              </v-select>
            </template>
            <template v-slot:item.due_amount="{ item }">
              <v-text-field
                style="margin-top:15px !important;"
                v-model="item.due_amount"
                solo
                :rules="[
                  validationRules.required,
                  validationRules.positiveFloatNumber
                ]"
                @keyup="CalculatTaxAmount(paymentObject.items.indexOf(item))"
              ></v-text-field>
            </template>
            <template v-slot:item.taxes="{ item }">
              <v-select
                style="margin-top:15px !important;"
                solo
                :items="taxes"
                v-model="item.taxes"
                item-text="name"
                item-value="id"
                multiple
                @change="CalculatTaxAmount(paymentObject.items.indexOf(item))"
              >
              </v-select>
            </template>
            <template v-slot:item.tax_amount="{ item }">
              <v-text-field
                style="margin-top:15px !important;"
                v-model="item.tax_amount"
                solo
                :rules="[validationRules.positiveFloatNumber]"
              ></v-text-field>
            </template>
            <template v-slot:item.discount="{ item }">
              <v-text-field
                style="margin-top:15px !important;"
                v-model="item.discount"
                solo
                :rules="[
                  validationRules.positiveFloatNumber,
                  DiscountLessAMonut(item.discount, item.due_amount)
                ]"
              ></v-text-field>
            </template>
            <template v-slot:item.net_amount="{ item }">
              <v-text-field
                style="margin-top:15px !important;"
                v-model="item.net_amount"
                solo
                :rules="[
                  validationRules.required,
                  validationRules.positiveFloatNumber
                ]"
              ></v-text-field>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon
                color="#ff8972"
                v-if="item.id"
                @click="
                  DeleteItemWithId(paymentObject.items.indexOf(item), item.id)
                "
                >delete</v-icon
              >
              <v-icon
                color="#ff8972"
                v-if="!item.id"
                @click="DeleteItemWithoutId(paymentObject.items.indexOf(item))"
                >delete</v-icon
              >
            </template>
          </v-data-table>
        </v-col>
      </v-row></v-form
    >
    <v-row
      ><v-col cols="12" style="text-align:center;"
        ><v-icon class="main-color" @click="addItem">add_circle</v-icon></v-col
      ></v-row
    >
    <hr class="second" />

    <v-row>
      <v-col cols="6" md="3" sm="6">
        <p class="stats">
          <strong>{{ $t("Total Before Tax") }}: </strong>
          {{ totalAmountBeforTax }}
        </p>
      </v-col>
      <v-col cols="6" md="3" sm="6">
        <p class="stats">
          <strong>{{ $t("Tax Amount") }}: </strong> {{ taxAmount }}
        </p>
      </v-col>
      <v-col cols="6" md="3" sm="6">
        <p class="stats">
          <strong>{{ $t("Total") }}: </strong> {{ total }}
        </p>
      </v-col>
      <v-col cols="6" md="3" sm="6">
        <p class="stats">
          <strong>{{ $t("Total after discount") }}: </strong>
          {{ totalAfterDiscount }}
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12" class="text-center">
        <v-btn @click="savePayment" color="#7297FF">{{
          $t("Update Payment")
        }}</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import { validationMixin } from "../../../mixins/validationMixin";
export default {
  name: "GeneralPayment",
  data() {
    return {
      headers: [
        {
          text: this.$i18n.t("Code"),
          align: "start",
          sortable: false,
          value: "code"
        },
        { text: this.$i18n.t("Name"), value: "account_id", sortable: false },
        { text: this.$i18n.t("Amount"), value: "due_amount", sortable: false },
        { text: this.$i18n.t("Tax"), value: "taxes", sortable: false },
        {
          text: this.$i18n.t("Tax Amount"),
          value: "tax_amount",
          sortable: false
        },
        { text: this.$i18n.t("Discount"), value: "discount", sortable: false },
        { text: this.$i18n.t("Net"), value: "net_amount", sortable: false },
        { text: this.$i18n.t("Actions"), value: "actions", sortable: false }
      ],

      paymentObject: {
        type: "",

        cash_point_id: "",
        transaction_date: "",
        transaction_id: "",
        transfer_number: "",
        transfer_date: "",
        items: [],
        partner_id: "",
        tax_registration: "",
        notes: "",
        amount_before_tax: "",
        tax_amount: "",
        total_amount: "",
        total_after_discount: ""
      },
      code: "",
      cash_points: [],
      options: {
        itemsPerPage: 100
      },

      selected_Items: [],
      accounts: [],
      submitted: false,
      snack: false,
      snackColor: "",
      snackText: "",
      snackTime: 7000,
      valid: false,
      lazy: false,
      partners: [],
      taxes: [],
      id: null
    };
  },
  mixins: [validationMixin],
  watch: {
    "paymentObject.items": {
      handler() {
        this.paymentObject.items.forEach(item => {
          if (item.due_amount != "" && item.due_amount != null) {
            item.net_amount =
              Math.round(
                (Number(item.due_amount) +
                  Number(item.tax_amount) -
                  Number(item.discount)) *
                  100
              ) / 100;
          }
        });
      },
      deep: true
    }
  },
  computed: {
    totalAmountBeforTax: {
      get() {
        let num = 0;
        this.paymentObject.items.forEach(item => {
          num += Number(item.due_amount);
        });

        return Math.round(num * 100) / 100;
      }
    },
    taxAmount: {
      get() {
        let num = 0;
        this.paymentObject.items.forEach(item => {
          num += Number(item.tax_amount);
        });

        return Math.round(num * 100) / 100;
      }
    },
    total: {
      get() {
        return (
          Math.round((this.totalAmountBeforTax + this.taxAmount) * 100) / 100
        );
      }
    },
    totalAfterDiscount: {
      get() {
        let discount = 0;
        this.paymentObject.items.forEach(item => {
          if (item.discount != "" && item.discount != null) {
            discount += Number(item.discount);
          }
        });

        return Math.round((this.total - discount) * 100) / 100;
      }
    }
  },
  methods: {
    DiscountLessAMonut(discount, amount) {
      if (discount > amount) {
        return "Not valid discount";
      }
      return true;
    },
    CalculatTaxAmount(index) {
      let taxes = this.paymentObject.items[index].taxes;
      let amount = this.paymentObject.items[index].due_amount;
      let tax_amount = 0;
      this.taxes.forEach(tax => {
        taxes.forEach(element => {
          if (tax.id == element) {
            tax_amount += (amount * tax.percentage) / 100;
          }
        });
      });
      this.paymentObject.items[index].tax_amount =
        Math.round(tax_amount * 100) / 100;
    },
    getPartnerCode() {
      this.partners.forEach(partner => {
        if (partner.id == this.paymentObject.partner_id) {
          if (partner.tax_registration != null) {
            this.paymentObject.tax_registration = partner.tax_registration;
          }

          this.code = partner.code;
        }
      });
    },
    getNameAccount(index, code) {
      let account_id = "";
      this.accounts.forEach(account => {
        if (account.code == code) {
          account_id = account.id;
        }
      });

      this.paymentObject.items[index].account_id = account_id;
    },
    getCodeAccount(index, account_id) {
      let code = "";
      this.accounts.forEach(account => {
        if (account.id == account_id) {
          code = account.code;
        }
      });

      this.paymentObject.items[index].code = code;
    },
    DeleteItemWithId(index, id) {
      axios
        .get(this.getApiUrl + "/general_payment/deleteDetail/" + id, {
          headers: {
            Authorization: "Bearer " + localStorage.token
          }
        })
        .then(response => {
          if (response.data.status.error == false) {
            this.DeleteItemWithoutId(index);
          }
        });
    },
    DeleteItemWithoutId(index) {
      this.paymentObject.items.splice(index, 1);
    },
    addItem() {
      let object = {
        code: "",
        account_id: "",
        due_amount: "",
        taxes: [],
        tax_amount: "",
        discount: "",
        net_amount: ""
      };
      this.paymentObject.items.push(object);
    },

    CheckDiscountAmount(discount, due_amount) {
      if (discount > due_amount) {
        return "Discount shouldnot be excced due amount";
      } else return true;
    },
    checkTransferDate(value) {
      if (this.paymentObject.type == "Bank Transfer") {
        return value ? true : "Transfer number is required";
      } else if (this.paymentObject.type == "Check") {
        return value ? true : "Check number is required";
      } else {
        return true;
      }
    },
    validateCheckDate(value) {
      if (this.paymentObject.type == "Check") {
        return value ? true : "Transfer number is required";
      } else {
        return true;
      }
    },
    selectPaymentMethod(method) {
      this.$refs.form.resetValidation();
      this.paymentObject.type = method;
      // this.paymentObject.cash_point_id = "";
      var _this = this;
      if (
        this.paymentObject.type == "Bank Transfer" ||
        this.paymentObject.type == "Check"
      ) {
        // this.paymentObject.transfer_number = "";
        $(document).ready(function() {
          $("#transfer_date").calendarsPicker({
            dateFormat: "dd/mm/yyyy",
            onSelect: function(date) {
              if (date[0]) {
                var dateH =
                  date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
                _this.paymentObject.transfer_date = dateH;
              } else {
                _this.paymentObject.transfer_date = "";
              }
            }
          });
        });
      }
      if (this.paymentObject.type == "Bank Transfer") {
        axios
          .get(this.getApiUrl + "/payments/getCashPoints/Transfer", {
            headers: {
              Authorization: "Bearer " + localStorage.token
            }
          })
          .then(response => {
            this.cash_points = response.data.data;
          });
      } else if (this.paymentObject.type == "Check") {
        // this.paymentObject.voucher_number = null;

        axios
          .get(this.getApiUrl + "/payments/getCashPoints/Check", {
            headers: {
              Authorization: "Bearer " + localStorage.token
            }
          })
          .then(response => {
            this.cash_points = response.data.data;
          });
      } else if (this.paymentObject.type == "Cash") {
        // this.paymentObject.transfer_number = "";
        this.paymentObject.transfer_date = "";
        // this.getPaymentData();
        axios
          .get(this.getApiUrl + "/payments/getCashPoints/Cash", {
            headers: {
              Authorization: "Bearer " + localStorage.token
            }
          })
          .then(response => {
            this.cash_points = response.data.data;
          });
      }
    },
    savePayment() {
      if (!this.valid) {
        this.$refs.form.validate();
      } else {
        this.paymentObject.amount_before_tax = this.totalAmountBeforTax;
        this.paymentObject.tax_amount = this.taxAmount;
        this.paymentObject.total_amount = this.total;
        this.paymentObject.total_after_discount = this.totalAfterDiscount;

        axios
          .post(
            this.getApiUrl + "/general_payment/update/" + this.id,
            this.paymentObject,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token
              }
            }
          )
          .then(response => {
            if (response.data.status.error == false) {
              this.$router.push(
                {
                  path: "/generalPayment/" + this.id
                },
                () => {}
              );
            }
          });
      }
    },
    getAllAcounts() {
      axios
        .get(this.getApiUrl + "/accounts/getAllAcounts", {
          headers: {
            Authorization: "Bearer " + localStorage.token
          }
        })
        .then(response => {
          this.accounts = response.data.data;
        });
    },
    partnersData() {
      axios
        .get(this.getApiUrl + "/partners/getPartnersNotStudentParent", {
          headers: {
            Authorization: "Bearer " + localStorage.token
          }
        })
        .then(response => {
          this.partners = response.data.data;
          this.taxesData();
          this.getPaymentData();
        });
    },
    taxesData() {
      axios
        .get(this.getApiUrl + "/gettaxes/getActiveTaxes", {
          headers: {
            Authorization: "Bearer " + localStorage.token
          }
        })
        .then(response => {
          this.taxes = response.data.data;
        });
    },
    getPaymentData() {
      axios
        .get(this.getApiUrl + "/general_payment/show/" + this.id, {
          headers: {
            Authorization: "Bearer " + localStorage.token
          }
        })
        .then(response => {
          if (response.data.status.error == false) {
            this.paymentObject = response.data.data;
            if (this.paymentObject.partner_id != null) {
              this.getPartnerCode();
            }

            // console.log(this.paymentObject);
            if (this.paymentObject.type != "") {
              this.selectPaymentMethod(this.paymentObject.type);
            }
          }
        });
    }
  },
  mounted() {
    this.id = this.$router.currentRoute.params.id;
    var _this = this;
    $(document).ready(function() {
      $("#transaction_date").calendarsPicker({
        dateFormat: "dd/mm/yyyy",
        onSelect: function(date) {
          if (date[0]) {
            var dateH =
              date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
            _this.paymentObject.transaction_date = dateH;
          } else {
            _this.paymentObject.transaction_date = "";
          }
        }
      });
    });

    this.getAllAcounts();
    this.partnersData();
  }
};
</script>
<style lang="scss" scoped>
@import "../../../styles/_variables.scss";
.info-list {
  .value {
    font-size: 13px;
  }
}
.paymentBtn {
  padding-top: 6px !important;
}
hr.basic {
  margin: 0;
}
hr.second {
  border: 2px solid $main-color;
  margin: 0 auto;
  background-color: $main-color;
}
.v-data-table th {
  height: 25px;
}

.box {
  box-shadow: $box-shadow;
  width: 90%;
  padding: 10px 10px;
  margin-bottom: 1rem;
  height: 222px;
  .key {
    margin-bottom: 8px;
  }
  .value {
    color: $main-gray;
    margin-bottom: 8px;
  }
}

.payment-methods {
  padding-left: 0;
  li {
    display: inline-block;
    width: 25%;

    .icon {
      font-size: 2rem;
      color: $main-color;
      margin-bottom: 8px;
      padding: 5px 20px 5px;
      margin: 0 5px;
      border-radius: 5px;
    }
    .text {
      color: $main-color;
    }
  }
}

.active {
  background-color: $main-backgroundColor;
}

.selectable {
  cursor: pointer;
}

.disabled {
  cursor: not-allowed;

  .icon {
    color: $main-gray !important;
  }

  .text {
    color: $main-gray !important;
  }
}
.payment_value {
  color: #646464 !important;
}

.selectInput {
  width: 90%;
  // padding-left: 12px;
  .v-input {
    max-width: 70%;
  }
}

.textAreaInput {
  // padding-left: 12px;
  width: 90%;
  label {
    color: $main-color;
  }
}

.calendar-icon {
  padding-right: 5px;
}
.due-date {
  color: #ff8972;
}

.second-title {
  color: $main-color;
}

@media (max-width: 600px) {
  .second-title {
    margin-bottom: 0;
  }
  .box {
    height: auto;
    width: 100%;
    .payment-methods {
      li {
        .icon {
          padding: 5px 10px 5px;
        }
      }
    }
  }

  .info-list .value {
    margin-bottom: 0;
  }

  .selectInput {
    width: 100%;
    .v-input {
      max-width: 100%;
    }
  }

  .textAreaInput {
    width: 100%;
  }
}
</style>
